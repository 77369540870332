@import 'src/styles/classes';

.container {
  .fluid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -2rem;

    .tile {
      cursor: pointer;
      margin: 0 1rem 2rem;
    }
  }
}

.menu {
  background: $background-lighter-100;
  padding: 2rem;
}

.event-modal {
  padding: 5rem;
  display: flex;

  .details {
    margin-left: 5rem;

    .title {
      font-family: $font-heavy;
      font-size: 4rem;
    }

    .purchase {
      margin-top: 5rem;
    }
  }
}

.events {
  padding-top: 1rem;
  background: $background-lighter-100;

  .group-wrapper {
    .group {
      padding: 0 2rem;

      .section {
        font-size: 16px;
        font-weight: 600;
      }

      .event-group {
        display: flex;
        flex-wrap: wrap;
        margin-right: -16px;

        .event {
          margin-bottom: 2rem;
          margin-right: 2rem;
          position: relative;
          cursor: pointer;

          .details {
            position: absolute;
            bottom: 0;
            backdrop-filter: blur(1rem);
            background: rgba(#000000, 0.2);
            width: 100%;
            padding: 1rem 2rem;
          }

          .thumbnail {
            overflow: hidden;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }

          .views {
            color: rgba($foreground, 0.4);
            font-size: 10px;
          }

          img {
            &.blur {
              filter: blur(0.8rem);
            }
          }
          .frosted {
            background: rgba(155, 0, 195, 0.25);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 158px;
            height: 158px;
            top: 0;
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }
}
