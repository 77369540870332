@import 'src/styles/classes';

.navbar {
  height: 7rem;
  background: $background-lighter-200;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $background-lighter-100;

  .search {
    width: 50rem;
    height: 6.5rem;
    position: relative;

    .searchbox {
      width: 100%;
      position: absolute;
      padding: 1rem;
      top: 0;

      &:focus-within {
        background: $background-lighter-300;
        border-radius: 1rem;
        box-shadow: rgba($black, 0.4) 0 1rem 2rem 0;

        .search-results {
          display: block;
        }
        input:focus-within {
          -webkit-box-shadow: 0 0 0px 1000px $background inset !important;
        }
      }
    }

    .search-results {
      display: none;
      margin-top: 1rem;
      @include scrollbar;
      max-height: 185px;
      overflow-y: auto;

      .item {
        padding: 1rem;
        cursor: pointer;
        border-radius: 1rem;

        &:hover {
          background: $background-lighter-200;
        }
      }

      .key-background {
        background: $background-lighter-200;
      }
    }
  }

  @include mobile {
    padding: 0 1rem;

    .search {
      width: 30rem;
    }
  }

  .logo {
    height: 4rem;
    width: inherit;
  }

  .menu {
    display: flex;
    align-items: center;
    width: 100%;

    .menu-item {
      cursor: pointer;
      position: relative;
      line-height: 0;
      overflow: initial;
      margin-left: 3rem;

      &.home {
        margin-left: 0;
      }

      &.item {
        .desktop {
          display: block;
        }

        .mobile {
          display: none;
        }
      }

      &.profile {
        .close-button {
          @include desktop {
            display: none;
          }

          .close {
            border: 1px solid $white;
            width: 4rem;
            height: 4rem;
          }
        }

        @include mobile {
          .avatar-button {
            &.hide {
              display: none;
            }
          }
        }
      }

      @include mobile {
        margin-left: 2rem;

        &.item {
          .desktop {
            display: none;
          }

          .mobile {
            display: block;
          }
        }

        &.profile {
          margin-left: 0;
        }
      }

      &.sidebar {
        margin-left: 0;

        .btn-sidebar {
          background: rgba($white, 0.2);
          padding: 0;
          width: 4rem;
          border-radius: 1rem;
        }
      }

      &.active {
        color: $primary;
      }

      .border {
        background: $primary;
        width: 2rem;
        height: 0.25rem;
        margin: auto;
        position: absolute;
        top: 1.5rem;
        left: 0;
        right: 0;
      }

      .preferences {
        position: absolute;
        background: $background-lighter-300;
        min-width: 25rem;
        right: -1rem;
        top: 5.5rem;
        line-height: initial;
        padding: 1rem 0;
        box-shadow: rgba($black, 0.4) 0 1rem 2rem 0;
        border-radius: 1rem;
        z-index: 10000;

        .arrow {
          position: absolute;
          top: -1rem;
          right: 2rem;
          width: 0;
          height: 0;
          border-left: 1rem solid transparent;
          border-right: 1rem solid transparent;
          border-bottom: 1rem solid $background-lighter-300;
        }

        @include mobile {
          right: 0;

          .arrow {
            right: 1rem;
          }
        }
      }

      .submenu {
        li {
          padding: 1rem 2rem;
          &.mobile {
            display: none;
          }

          @include mobile {
            &.mobile {
              display: block;
            }
          }

          &:hover {
            background: $background-lighter-400;
          }

          .fanbucks {
            font-size: 25px;
          }
        }
      }
    }
  }

  .notification-badge {
    position: absolute;
    top: -2px;
    right: -3px;
    padding: 5px 5px;
    border-radius: 50%;
    background: red;
    color: white;
  }
}
