@import 'src/styles/classes';

.sublabel {
  margin-top: 0.5rem;
  font-size: $size-8;
}

.input-spacer {
  margin-top: 4px;
}

.layer {
  &-0 {
    .field {
      background: none;
    }
  }

  &-1 {
    .field {
      background: $background-lighter-100;

      &:focus-within {
        background: $background;
      }
    }
  }
}
