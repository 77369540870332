$size-4: 3rem;
$size-5: 2.5rem;
$size-6: 2rem;

@import 'styles/variables';
@import 'styles/fonts';
@import 'styles/globals';
@import '~bulma/sass/utilities/all';
@import '~bulma/sass/grid/all';
@import '~bulma/sass/helpers/all';

* {
  box-sizing: border-box;
}

html {
  font-size: 8px;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $foreground;
  background: $background;
  overflow: hidden;
}

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

$spacers: (
  0: 0,
  1: 0.5rem,
  2: 1rem,
  4: 2rem,
  6: 3rem,
  8: 4rem,
  32: 16rem,
  auto: auto
);
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .ft-#{$abbrev}-#{$size} {
      #{$prop}: $length;
    }
    .ft-#{$abbrev}t-#{$size},
    .ft-#{$abbrev}y-#{$size} {
      #{$prop}-top: $length;
    }
    .ft-#{$abbrev}r-#{$size},
    .ft-#{$abbrev}x-#{$size} {
      #{$prop}-right: $length;
    }
    .ft-#{$abbrev}b-#{$size},
    .ft-#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length;
    }
    .ft-#{$abbrev}l-#{$size},
    .ft-#{$abbrev}x-#{$size} {
      #{$prop}-left: $length;
    }
  }
}
