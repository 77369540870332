@import 'src/styles/classes';

.comment {
  padding: 0 1rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.highlight {
    animation: highlight-fade 2s;
  }

  .controls {
    font-size: 10px;

    div {
      cursor: pointer;
    }

    .moment {
      color: rgba($foreground, 0.4);
    }
  }
}

.chat {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .avatar {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    margin-bottom: -0.25rem;
  }
  span.sender {
    display: inline-block;
  }
  span.message {
    word-break: break-word;
  }
}

.tip {
  background: $background-lighter-100;
  border-left: 0.5rem solid $grapple;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .detail {
    padding: 1rem;

    .header {
      color: $grapple;
      display: flex;
      align-items: center;

      .avatar {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
      }
    }
  }
}

@keyframes highlight-fade {
  0% {
    background: rgba($primary, 0.4);
  }

  25% {
    background: rgba($primary, 0.4);
  }

  100% {
    background: transparent;
  }
}

.badge {
  width: 14px;
}

.delete:hover {
  color: $red;
}
