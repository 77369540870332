@import 'src/styles/classes';

.item-wrapper {
  width: 120rem;
  margin: auto;
  padding-top: 5rem;

  .item-preview {
    width: 50rem;
  }

  .no-stretch {
    flex-grow: 0;
    margin-right: 3rem;
  }

  .purchase-button {
    width: 40rem;
    margin: auto;
  }
}
