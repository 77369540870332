@import 'src/styles/classes';

.header {
  background: $background-lighter-200;
}

.item-thumbnail {
  width: 120px;
  height: 120px;
}

.moss {
  line-height: 1.5;

  .subtotal {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid #ffffff;
  }
}

.purchase-complete {
  height: 400px;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  padding: 32px;
}
