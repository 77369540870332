@import 'src/styles/classes';

.theater {
  flex: 1;
  background: #000000;
  height: 100%;

  .media {
    width: 100%;
    height: 100%;
    margin: auto;

    &.image {
      object-fit: contain;
    }

    &.audio {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      canvas {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .center-media {
        z-index: 10;
        .cover-art {
          width: 320px;
          height: 320px;
        }

        .playback {
          text-align: center;
          color: black;

          .title {
            margin: 8px 0;

            .song {
              font-size: 16px;
              font-weight: 600;
            }
          }

          .seeker {
            height: 2px;
            position: relative;

            .playhead {
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              margin: 0;
              appearance: none;
              background: transparent;

              &::-webkit-slider-thumb {
                appearance: none;
                background: $primary;
                width: 12px;
                height: 12px;
                border-radius: 50%;
              }
            }

            .progress {
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              appearance: none;
              pointer-events: none;

              &::-webkit-progress-bar {
                background: rgba(black, 0.4);
              }

              &::-webkit-progress-value {
                background: black;
              }
            }
          }

          .timers {
            font-size: 12px;
          }

          .controls {
            .play-pause {
              margin: 0 48px;
            }
          }
        }
      }
    }
  }
}

.siderail {
  background: $background-darker-100;
  width: 320px;
  height: 100%;
  padding: 16px;

  .controls {
    display: flex;
  }

  .moment {
    font-size: 10px;
    color: rgba(#ffffff, 0.4);
  }

  .engagement {
    color: rgba(#ffffff, 0.8);

    .count {
      margin-left: 4px;
    }
  }

  .comments {
    margin-top: 16px;

    .section {
      font-size: 12px;
      color: rgba($foreground, 0.6);
    }

    .comment-list {
      height: calc(100vh - 193px);
    }

    .avatar {
      width: 24px;
      height: 24px;
    }
  }

  .audio {
    .album {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .tracks {
      margin-left: -16px;
      margin-right: -16px;
      flex: 1;

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          cursor: pointer;

          &:nth-child(odd) {
            background: $background;
          }

          &:nth-child(even) {
            background: $background-darker-100;
          }

          &.selected {
            background: linear-gradient(to right, rgba($primary, 0.2), rgba($secondary, 0.2)) fixed;
          }

          &:hover {
            background: $primary;
          }

          td {
            height: 32px;
            padding: 0 8px;
            border: none;

            &.track-name {
              width: 100%;
            }

            &:first-child {
              padding-left: 16px;
            }

            &:last-child {
              padding-right: 16px;
            }
          }
        }
      }
    }
  }
}

.dark {
  color: rgba($foreground, 0.4);
  border: 1px solid rgba($foreground, 0.4);
  background: none;

  &:active {
    background: rgba($foreground, 0.2);
  }
}
