@import 'src/styles/classes';

.champ {
  text-transform: uppercase;
  font-size: $size-8;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: inline-block;

  &.green {
    background: $green;
  }

  &.peach {
    background: $peach;
  }

  &.red {
    background: $red;
  }

  &.teal {
    background: $teal;
  }

  &.yellow {
    background: $yellow;
  }
}
