@import 'src/styles/classes';

.new-card {
  .row {
    display: flex;
    margin: 0 -1rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    > div {
      padding: 0 1rem;
    }

    .w-25 {
      width: 25%;
    }

    .w-33 {
      width: 33.33%;
    }

    .w-50 {
      width: 50%;
    }

    .w-100 {
      width: 100%;
    }
  }
}

.credit-cards {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  .card {
    width: 36%;
    height: 15rem;
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    cursor: pointer;

    &.selected {
      box-shadow: 0 0 0 0.5rem #ffffff;
    }

    .background {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .detail {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 1.5rem;
      display: flex;
      align-items: flex-end;
    }
    .delete {
      position: absolute;
      padding: 1.5rem;
      margin-left: calc(100% - 5.5rem);
      background-color: transparent;
      z-index: 1;
    }
    .card-type {
      position: absolute;
      right: 1rem;
      bottom: 1.2rem;
      font-weight: bold;
      max-width: 60px;
      max-height: 36px;
      overflow: hidden;
    }
  }
}

.confirm {
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.payment-modal {
  padding: 5rem;

  .confirm {
    display: flex;
    margin-top: 2rem;
    flex-wrap: wrap;
    flex-direction: row-reverse;

    @include mobile {
      margin-bottom: -1rem;

      div {
        margin-bottom: 1rem;
      }
    }
  }

  .finalize {
    padding: 2rem;
    border-bottom: 1px solid rgba($white, 0.2);

    .total {
      margin-top: 1rem;
      padding-top: 1rem;
      text-align: right;
    }
  }

  .amount {
    font-size: $size-6;
    display: flex;
    align-items: center;
    line-height: 0;
    margin-left: auto;
  }

  .purchase-text {
    text-align: right;
  }
}
