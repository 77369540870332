@import 'src/styles/classes';

.media {
  width: 100%;
  height: 100%;
  margin: auto;
}
.livestream-player-controls {
  padding: 0 2rem;
  bottom: 1rem;
  position: absolute;
  width: 100%;

  .live-indicator {
    height: 1.5rem;
    width: 1.5rem;
    background-color: $red;
    border-radius: 50%;
    display: flex;
    margin-right: 1rem;
  }
  .live-text {
    text-transform: uppercase;
    line-height: 0;
  }

  .volume-wrapper {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    width: 3rem;
    margin-left: 2rem;
    transition: 250ms ease;

    &:hover {
      width: 20rem;
      overflow: visible;
    }

    .volume {
      width: 3rem;
      text-align: left;
    }
  }
  .volume-container {
    position: relative;
    width: 15rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;

    .volume-container-inner-wrapper {
      height: 1rem;
      position: relative;
    }

    .volume-level {
      height: 0.5rem;
      background-color: $primary;
      position: absolute;
      z-index: 100;
    }

    .volume-control {
      margin: 0;
      -webkit-appearance: none;
      background-color: rgba($primary, 0.2);
      height: 0.5rem;
      z-index: 110;
      position: absolute;
      top: 0;
      width: 15rem;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 1.5rem;
        height: 1.5rem;
        background: $white;
        border-radius: 50%;
      }
    }
  }

  .mute-btn {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  button.full-screen {
    margin-left: auto;
  }
}
