@import 'src/styles/classes';

.container {
  @include scrollbar;
  display: flex;
  flex: 1;
  padding-top: 2rem;
  height: calc(100vh - 7rem);
  overflow-y: scroll;
  margin-left: 30rem;

  @include mobile {
    margin-left: 7rem;

    &.hide {
      margin-left: 0;
    }
  }

  .profile {
    width: 80rem;
    margin-left: auto;
    margin-right: 1rem;
    position: relative;

    .profile-float {
      position: absolute;
      width: 100%;
    }

    .header {
      .banner {
        height: 180px;
        cursor: pointer;
      }

      .creator-info {
        padding: 16px 24px;
        margin-top: -32px;

        .avatar-creator {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          box-shadow: 0 0 0 4px $background-lighter-200;
          overflow: hidden;
          cursor: pointer;
        }

        .creator-details {
          margin-bottom: -24px;

          .name {
            font-size: 28px;
            font-weight: 700;

            .category {
              border: 1px solid $foreground;
              font-size: 8px;
              font-weight: 400;
              padding: 0.25rem;
              display: inline-block;
              margin-left: 1rem;
            }
          }

          .sub {
            color: $secondary;
          }
        }

        .about {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          max-height: 80px;
          transition: all 250ms ease;

          &.open {
            -webkit-line-clamp: unset;
            max-height: 400px;
          }
        }

        .more {
          position: relative;

          .context-menu {
            position: absolute;
            background: $background-lighter-300;
            width: 20rem;
            left: 50%;
            margin-left: -10rem;
            top: 4.5rem;
            padding: 1rem 0;
            box-shadow: rgba($black, 0.8) 0 1rem 2rem 0;
            border-radius: 1rem;
            z-index: 100;

            .arrow {
              position: absolute;
              top: -1rem;
              left: 50%;
              margin-left: -1rem;
              width: 0;
              height: 0;
              border-left: 1rem solid transparent;
              border-right: 1rem solid transparent;
              border-bottom: 1rem solid $background-lighter-300;
            }
          }

          .submenu {
            li {
              padding: 1rem 2rem;
              cursor: pointer;
              text-align: center;

              &:hover {
                background: $background-lighter-400;
              }
            }
          }
        }
      }
    }

    .promo {
      position: relative;
      height: 15rem;
      margin-top: 2rem;

      .image-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .details {
        position: relative;
        z-index: 10;
        height: 100%;

        .description {
          max-width: 320px;
        }
      }
    }

    .menu-wrapper {
      background: $background;
      padding-top: 2rem;
      position: sticky;
      position: -webkit-sticky;
      top: -2rem;
      z-index: 1;

      .menu {
        background: $background-lighter-200;
        border-bottom: 1px solid $background;
        height: 6rem;
        padding: 0 3rem;

        .tab {
          height: 100%;

          .tab-item {
            cursor: pointer;
            margin-right: 3rem;
            position: relative;

            &.active {
              color: $primary;
            }

            .border {
              background: $primary;
              width: 16px;
              height: 2px;
              margin: 4px auto auto;
              position: absolute;
              left: 0;
              right: 0;
            }
          }
        }
      }
    }

    @include mobile {
      width: 100%;

      .name {
        font-size: $size-5 !important;
      }
    }
  }

  .club {
    width: 35rem;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    margin-left: 1rem;
    margin-right: auto;

    @include mobile {
      display: none;
    }

    .top-fans {
      padding: 16px;

      .header {
        font-size: 16px;
        font-weight: 600;
      }

      .avatar {
        width: 32px;
        height: 32px;
        overflow: hidden;
      }

      .badge {
        margin-left: 4px;
        width: 14px;
      }

      .cursor {
        cursor: pointer;
      }
    }

    .pre-subscription {
      height: 200px;
      position: relative;
      margin-top: 2rem;

      .background {
        width: 100%;
        height: 100%;
        position: absolute;
      }

      .foreground {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 16px;
        background: rgba(#000000, 0.6);
        text-align: center;
      }
    }

    .post-subscription {
      border: 1px solid $primary;
      padding: 2rem;
      text-align: center;
      margin-top: 2rem;
    }
  }
}

.tile {
  background: $background-lighter-200;
}

.hidden {
  visibility: hidden;
}

.scroll-y {
  @include scrollbar(transparent);
  overflow-y: auto;
  height: 200px;
}

.right {
  position: absolute;
  right: 0;
}

.right-badge {
  position: absolute;
  right: 13rem;
}

.badge {
  width: 25px;
  height: 25px;
}

.sticky {
  width: 80rem;
  margin: auto;
  display: flex;
  align-items: center;
  background: $background-lighter-300;
  border-bottom: 1px solid $background-lighter-100;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  cursor: pointer;

  .avatar {
    border-radius: 0;
    width: 12rem;
    height: 12rem;
  }

  .views {
    font-size: $size-7;
    color: $foreground-muted;
  }
}

.player {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top: 0;
}

.layout {
  display: flex;
  height: 100%;

  @include mobile {
    flex-direction: column;

    .theater {
      flex: unset;
      height: 30%;
    }
  }
}

.theater {
  flex: 1;
  background: #000000;
  height: 100%;
  position: relative;

  .media {
    width: 100%;
    height: 100%;
    margin: auto;

    &.image {
      object-fit: contain;
    }
  }
}

.subscribed {
  font-size: 12px;
}

.fl-right {
  float: right;
}

.promo-wrapper {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  @include scrollbar;

  .post:first-child {
    margin-left: 0px;
    margin-right: 8px;
  }
  .post:last-child {
    margin-left: 8px;
    margin-right: 0px;
  }
  .post {
    margin: 0 8px;
  }
}
