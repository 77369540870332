@import 'src/styles/classes';

.skeleton {
  animation: pulse 3s infinite;

  &.circle {
    border-radius: 50%;
  }
}

@keyframes pulse {
  0% {
    background: $background-lighter-400;
  }

  15% {
    background: lighten($background-lighter-400, 20%);
  }

  30% {
    background: $background-lighter-400;
  }

  100% {
    background: $background-lighter-400;
  }
}
