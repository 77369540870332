@import 'src/styles/classes';
.wrapper {
  background: $background-lighter-100;

  .livestreams {
    padding-top: 2rem;

    .grid {
      display: flex;
      flex-wrap: wrap;
      margin: -1px;
      padding: 0 1px;

      .image-wrapper {
        position: relative;
        cursor: pointer;
        width: 19.75rem;
        height: 19.75rem;
        margin: 1px;
        overflow: hidden;

        .thumbnail {
          width: 100%;
          height: 100%;
          img {
            &.blur {
              filter: blur(6rem);
            }
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }
}
