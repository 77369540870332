@import 'src/styles/classes';

.messages {
  @include scrollbar(transparent);
  overflow-y: auto;
  height: calc(100% - 2.5rem);

  .item {
    height: 7rem;
    padding: 0 2rem;
    border-top: 1px solid $background-lighter-200;
    cursor: pointer;

    &:last-child {
      border-bottom: 1px solid $background-lighter-200;
    }

    .message {
      width: calc(100% - 5rem);

      .user {
        display: flex;
        align-items: center;
        text-transform: lowercase;

        .badge {
          width: 1rem;
          height: 1rem;
          margin-left: 0.5rem;
          border-radius: 50%;
          background: $primary;
          visibility: hidden;
        }
      }

      .wrap-text {
        font-size: 12px;
      }

      .moment {
        align-self: flex-start;
        font-size: $size-8;
        color: $foreground-muted;
        text-align: right;
      }
    }

    &.unread {
      background: $background-lighter-400;

      .user {
        font-weight: 600;

        .badge {
          visibility: visible;
        }
      }
    }
  }
}

.chat {
  @include scrollbar($background-lighter-300);
  height: calc(100% - 13.5rem);
  overflow-y: auto;
  padding: 0 2rem;

  .message {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.5rem;

    .bubble {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      white-space: normal;
      word-break: break-word;
    }

    .moment {
      font-size: $size-8;
      color: $foreground-muted;
      margin-left: 1rem;
      visibility: hidden;
    }
  }

  .me {
    .message {
      justify-content: flex-end;

      .bubble {
        background: $primary;
      }
    }
  }

  .them {
    .bubble {
      background: rgba($white, 0.2);
    }
  }

  .me,
  .them {
    margin-bottom: 1rem;
  }
}

.cursor {
  cursor: pointer;
}

.input {
  padding: 0 1rem;
}

.message-options {
  display: none;
}

.message:hover .message-options {
  display: block;
}
