@import 'src/styles/classes';

.snackbar {
  position: fixed;
  z-index: 1000;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  max-width: 50rem;
  padding: 1rem 2rem;
  border-radius: 1rem;
  background-color: #0f2de1;
  color: white;
  box-shadow: rgba($black, 0.8) 0 1rem 2rem 0;

  display: flex;
  align-items: center;

  animation: fadein 250ms, fadeout 250ms 3.75s;

  @include mobile {
    max-width: calc(100vw - 5rem);
    min-width: calc(100vw - 5rem);
  }
}

.default {
  background-color: $background-lighter-300;
}

.success {
  background-color: $green;
}

.danger {
  background-color: $olympus;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 4rem;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 4rem;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
