@import 'src/styles/classes';

.toast {
  // position: fixed;
  z-index: 1000;
  height: auto;
  max-width: 36rem;
  padding: 1rem 2rem;
  border-radius: 1rem;
  color: white;
  box-shadow: rgba($black, 0.8) 0 1rem 2rem 0;
  background-color: $background-lighter-300;

  transition: 1s ease;

  display: flex;
  align-items: center;

  animation: fadein 250ms, fadeout 250ms 3.75s;

  &.video-call {
    max-width: fit-content !important;
    min-width: 36rem;
  }

  @include mobile {
    max-width: calc(100vw - 5rem);
    min-width: calc(100vw - 5rem);
  }

  .content {
    flex-direction: column;
  }
}

.close-toast {
  position: absolute;
  top: -0.8rem;
  right: 0.2rem;
  cursor: pointer;
  z-index: 10000;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 4rem;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 4rem;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.pointer {
  cursor: pointer;
}

.call-buttons {
  flex-direction: row;
  .call {
    display: inline-block;
    padding: 1rem;
    height: 36px;
    width: 90px;
    border-radius: 1rem;
    text-align: center;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    &.answer {
      background: $primary;
    }
    &.hang-up {
      background-color: $red;
    }
  }
}
