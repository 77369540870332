@import 'src/styles/classes';

.container {
  display: flex;

  .sidebar {
    background: $background-darker-100;
    border-right: 1px solid $background-lighter-100;
    position: relative;
    padding: 0;

    .creator {
      width: unset;
    }
  }

  .channel-sidebar {
    background: $background-darker-100;
    height: calc(100vh - 7rem);
    width: 30rem;
    border-right: 1px solid $background-lighter-100;

    .merchant {
      background: $background-lighter-100;
      font-weight: 700;
      font-size: 16px;
      padding: 0 2rem;
      height: 5.5rem;
      line-height: 5.5rem;
    }

    .wrapper {
      padding: 2rem 0;
      display: flex;
      flex-direction: column;
      height: calc(100% - 5.5rem);
      flex: 1;
      position: relative;

      .list {
        @include scrollbar;
        flex: 1;
        overflow-y: auto;

        .channels {
          margin-top: 1rem;
          margin-right: 1rem;
          padding-left: 1rem;

          .channel-row {
            display: flex;
            align-items: center;
            padding: 0.75rem 1rem;
            cursor: pointer;

            &:hover {
              background: $background-lighter-100;

              .menu {
                visibility: visible;
              }
            }

            &.selected {
              background: $background-lighter-200;

              .channel {
                color: $primary;
              }
            }

            .channel {
              cursor: pointer;
            }

            .menu {
              visibility: hidden;
              margin-left: auto;
              display: flex;

              &.sticky {
                visibility: visible;
              }

              .ellipsis {
                height: 2rem;
                width: 2rem;
                padding: 0;
                background-color: transparent;
              }

              .pin {
                cursor: pointer;

                &:hover {
                  color: lighten($primary, 10);
                }
              }

              .edit {
                cursor: pointer;

                &:hover {
                  color: lighten($primary, 10);
                }
              }

              .delete {
                cursor: pointer;

                &:hover {
                  color: lighten($primary, 10);
                }
              }
            }
          }
        }
      }

      .action {
        padding: 2rem 2rem 0;
        flex-shrink: 0;
        width: 100%;
        text-align: center;
      }
    }
  }

  .content {
    height: calc(100vh - 7rem);
    max-width: 100rem;
    width: 100rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .channel-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: calc(100% - 68px);

      .celestial {
        max-height: 100%;
        overflow-y: auto;
        @include scrollbar(transparent);
      }

      .channel-name {
        font-size: $size-4;
        padding: 2rem 0;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        background: $background;
      }

      .channel-conversations {
        .messages-box {
          overflow-x: hidden;

          .message-row {
            display: flex;

            &:not(:first-child) {
              margin-top: 1rem;
            }

            .message-icon {
              width: 3rem;
              height: 3rem;
              border-radius: 2rem;
              flex-shrink: 0;
              overflow: hidden;
              cursor: pointer;
            }

            .message-user {
              color: $primary;
              margin-right: 1rem;
              cursor: pointer;
            }

            .message-content {
              margin-right: 1rem;
            }

            .message-timestamp {
              font-size: $size-8;
              color: $foreground-muted;
            }
          }
        }

        .users {
          display: flex;
          margin: 0 -1.5rem;

          .user {
            padding: 1.5rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .avatar {
              width: 10rem;
              height: 10rem;

              &.speaking {
                border: 1px solid $success;
              }
            }

            .participant-type {
              font-size: $size-8;
              color: $foreground-muted;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .chat {
      margin: 2rem 0;

      &.request {
        display: flex;
        justify-content: center;
        margin-bottom: 4rem;
      }
    }
  }
}

.hidden {
  visibility: hidden;
}
