@import 'src/styles/classes';

.activity {
  background: $background-lighter-100;

  .post {
    padding-top: 16px;

    &:last-child {
      padding-bottom: 16px;
    }
  }
}
