@import 'src/styles/classes';

.context-menu {
  position: absolute;
  background: $background-lighter-300;
  width: 20rem;
  left: 50%;
  margin-left: -10rem;
  top: 4.5rem;
  padding: 1rem 0;
  box-shadow: rgba($black, 0.8) 0 1rem 2rem 0;
  border-radius: 1rem;
  z-index: 1000;

  .arrow {
    position: absolute;
    top: -1rem;
    left: 50%;
    margin-left: -1rem;
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid $background-lighter-300;
  }

  &.mobile {
    left: unset;
    margin-left: unset;
    right: 5rem;
    top: -1rem;
    width: 15rem;

    .arrow {
      left: unset;
      margin-left: unset;
      right: -1.5rem;
      top: 2rem;
      transform: rotate(90deg);
    }

    .submenu {
      ul > li {
        padding: 0.5rem 1rem;
      }
    }
  }

  &.channel {
    left: unset;
    margin-left: unset;
    right: 3.5rem;
    top: -1.5rem;
    width: 12rem;

    /*position: fixed;
    right: unset;
    margin-right: unset;
    left: 43rem;
    top: 10rem;
    width: 12rem;*/

    .arrow {
      left: unset;
      margin-left: unset;
      right: -1.5rem;
      top: 2rem;
      transform: rotate(90deg);
    }

    .submenu {
      ul > li {
        padding: 0.5rem 1rem;
      }
    }
  }
}

.submenu {
  ul > li {
    padding: 1rem 2rem;
    cursor: pointer;
    text-align: center;

    &:hover {
      background: $background-lighter-400;
    }
  }
}
