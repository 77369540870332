@import 'src/styles/classes';

.gallery-wrapper {
  background: $background-lighter-100;

  .gallery {
    padding-top: 2rem;

    .grid {
      display: flex;
      flex-wrap: wrap;
      margin: -1px;
      padding: 0 1px;

      .image-wrapper {
        position: relative;
        cursor: pointer;
        width: 19.75rem;
        margin-left: 1px;
        margin-right: 1px;
        margin-bottom: 2rem;
        overflow: hidden;

        .thumbnail {
          width: 158px;
          height: 158px;
          img {
            &.blur {
              filter: blur(0.8rem);
            }
          }
        }

        .frosted {
          background: rgba(155, 0, 195, 0.25);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 158px;
          height: 158px;
          top: 0;
        }

        .name {
          font-size: 12px;
        }

        .views {
          color: rgba($foreground, 0.4);
          font-size: 10px;
        }
      }
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }
}
