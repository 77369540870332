@import 'src/styles/classes';

.notifications-wrapper,
.announcement-wrapper {
  width: inherit;
  @include scrollbar(transparent);
  overflow-y: auto;
}
.notifications-wrapper {
  height: calc(100% - 5.25rem);
}

.announcement-wrapper {
  height: calc(100% - 3.5rem);

  .message {
    font-size: 12px;
  }
}

.item {
  align-items: flex-start;
  padding: 1.5rem;
  //height: 8rem;
  border-top: 1px solid #2d2b37;
  word-break: break-word;

  .moment {
    font-size: $size-8;
    color: $foreground-muted;
  }
}

.cursor {
  cursor: pointer;
}

.unread-notif {
  background-color: $background-lighter-400;
}

.mark-as-read {
  font-size: 12px;
  height: 2rem;
  right: 0;
  float: right;
  vertical-align: text-top;
}

.media {
  width: 100%;
  height: 100%;
  margin: auto;
}
