@import 'src/styles/classes';
.code {
  padding: 0px;
  font-size: 60px;
  height: 80px;
  width: 50px;
  text-align: center;
  background: $background-lighter-100;
  border: 0;
  margin-right: 0.5rem;
  color: #ffffff;
  outline: none;
  border-radius: 1rem;
  &:focus {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0px 1000px $background-lighter-100 inset;
    -webkit-text-fill-color: #ffffff;
  }
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $background inset;
  }
  &::placeholder {
    color: rgba($foreground, 0.4);
  }
}
