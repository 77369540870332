@import 'src/styles/classes';

.react-calendar {
  margin-top: 2.5rem;
  width: auto !important;
  background: $background-lighter-300 !important;
  line-height: 1;
  border: none !important;
  padding: 1rem;

  &__tile:disabled {
    background-color: $background-lighter-300 !important;
    color: rgba($black, 0.2) !important;
  }
  &__navigation {
    height: 4rem !important;
    margin-bottom: 1rem !important;
    display: flex;

    button {
      font-family: $font-family;
      color: $foreground;
      padding: 0;
      min-width: 4rem;
      font-size: 12px;

      &:hover {
        background: $background-lighter-400 !important;
      }

      &:disabled {
        color: rgba($black, 0.2) !important;
        background-color: unset !important;
      }
    }
  }

  &__tile {
    padding: 0;
    height: 4rem;
    font-family: $font-family;
    font-size: 12px;
    color: $foreground;

    &:hover {
      background-color: #2c2b36 !important;
    }

    &--now {
      color: $primary;
      background: none !important;
    }

    &--active {
      background: $primary !important;
      color: $foreground !important;
    }

    &--hasActive {
      background: $primary;
      color: $foreground;
    }
  }

  &__month-view {
    &__weekdays {
      font-family: $font-family;
      font-size: $size-8;

      abbr {
        text-decoration: none;
      }

      &__weekday {
        padding: 0.5rem;
      }
    }

    &__days {
      &__day {
        &--weekend {
          color: rgb(255 255 255 / 80%);
          // color: $foreground !important;
        }

        &--neighboringMonth {
          color: #fff !important;
        }

        &:disabled {
          background-color: $background-lighter-300 !important;
          color: rgb(255 255 255 / 80%) !important;
        }
      }
    }
  }
}

.custom-input {
  visibility: hidden;
}

.has-content {
  color: #5f74f4;
}

.row {
  cursor: pointer;
  height: 50px;
  .time {
    width: 70px;
  }
}

.event-modal {
  padding: 5rem;
  display: flex;

  .details {
    margin-left: 5rem;

    .title {
      font-family: $font-heavy;
      font-size: 4rem;
    }

    .purchase {
      margin-top: 5rem;
    }
  }
}
