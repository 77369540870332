@import 'src/styles/classes';

.container {
  .content {
    .main {
      max-width: 114rem;
      margin: auto auto 12rem;

      .section {
        font-weight: 600;
        font-size: $size-6;
      }

      .fluid-wrapper {
        .fluid {
          margin-left: -0.5rem;
          margin-right: -0.5rem;
          max-width: 114rem;
          display: flex;
          flex-wrap: wrap;

          @include mobile {
            margin-right: 0;
          }
        }
      }

      .featured {
        position: relative;
        margin: 0 0.5rem;

        .image-wrapper {
          width: 27.5rem;
          height: 32rem;
        }

        .overlay {
          position: absolute;
          bottom: 0;
          padding: 8px;
          width: 100%;
          backdrop-filter: blur(16px);
          background: rgba(#000000, 0.4);

          .category {
            border: 1px solid $foreground;
            font-size: 8px;
            padding: 2px;
            display: inline-block;
          }

          .name {
            font-weight: 600;
          }

          .sub {
            font-size: 10px;
          }
        }
      }

      .tile-sm {
        margin: 3rem 0.5rem 0;

        .image-wrapper {
          cursor: pointer;

          .avatar {
            width: 12rem;
            height: 12rem;
            margin: auto;
          }

          .event {
            width: 18rem;
            height: 11rem;
          }
        }

        .wrap-text {
          width: 18rem;
          color: $primary;
          text-align: center;
          margin-top: 1rem;

          .sub {
            color: $foreground;
          }
        }

        .sub {
          font-size: 10px;
        }
      }

      .tile-lg {
        margin-left: 4px;
        margin-right: 4px;
        position: relative;

        .image-wrapper {
          width: 296px;
          height: 160px;
        }

        .sub {
          position: absolute;
          left: 8px;
          bottom: 8px;
          font-size: 16px;
          font-weight: 600;
          text-shadow: 1px 1px rgba(#000000, 0.4);
        }
      }
    }
  }
}

.skeleton-wrapper {
  width: 144px;
  height: 133px;
  margin: 3rem 0.5rem 0;
  .avatar,
  .skeleton-name,
  .skeleton-follower {
    margin: auto;
  }
}
