@import 'src/styles/classes';

.wrapper {
  position: relative;
  display: inline-block;

  .wrapper-inner {
    display: flex;
  }
}
