@import 'src/styles/classes';

.collapse {
  overflow: hidden;
  transition: 250ms max-height;
}

.collapse > div {
  overflow: auto;
}

.hidden {
  display: none;
}
