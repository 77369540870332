@import 'src/styles/classes';

.merchandise-wrapper {
  background: $background-lighter-100;
  padding: 2rem 0;

  .merchandise {
    cursor: pointer;

    .grid {
      .grid-inner {
        padding: 1rem 0;

        &:hover {
          background: $background-lighter-200;
        }
      }

      .thumbnail {
        width: 160px;
        height: 160px;
      }

      .name {
        font-size: 12px;
      }

      .views {
        color: rgba($foreground, 0.4);
        font-size: 10px;
      }
    }

    &:not(:last-child) {
      padding-bottom: 2rem;
    }
  }
}
