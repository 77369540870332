@import 'src/styles/classes';

.content {
  display: flex;

  .main {
    width: 80%;
    margin-right: 3rem;
  }

  .side {
    width: 20%;
  }
}

.control {
  margin-bottom: 2rem;
}

.profile-picture {
  width: 15rem;
  height: 15rem;
  margin: 1rem auto auto;
  position: relative;

  .avatar {
    width: 100%;
    height: 100%;
  }

  .more {
    border-color: $foreground;
    color: $foreground;
    background: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
