@import 'src/styles/classes';

.icon {
  font-size: 16px;

  &.xs {
    font-size: 10px;
  }
  &.sm {
    font-size: 12px;
  }
  &.lg {
    font-size: 24px;
  }
  &.xl {
    font-size: 32px;
  }
}

.svg {
  display: inline-block;

  img {
    width: unset;
    height: 12px;
    margin-bottom: -1px;
  }
}
