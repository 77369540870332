// HeyFans
$legacy: #5f74f4;
$horizon: #be6bd8;
$newton: #ef8440;
$olympus: #d02e5a;
$grapple: #53bef5;

$green: #00b488;
$lime: #a6d668;
$peach: #e58961;
$red: #e3440a;
$teal: #03bfcf;
$turquoise: #43e0c4;
$yellow: #eeaf0e;

// Colors
$primary: $legacy;
$secondary: $horizon;
$foreground: #ffffff;
$foreground-muted: rgba(#ffffff, 0.4);
$background: #1b1a21;
$background-lighter-100: #24232c;
$background-lighter-200: #2d2b37;
$background-lighter-300: #363442;
$background-lighter-400: #3f3d4d;
$background-darker-100: #121116;
$black: #000000;
$white: #ffffff;
$success: $green;

// Typography;
$font-family: 'Inter', sans-serif;
$font-heavy: nimbus-sans-extended, sans-serif;
$size-1: 32px;
$size-2: 28px;
$size-4: 24px;
$size-5: 20px;
$size-6: 16px;
$size-7: 12px;
$size-8: 10px;
$size-normal: 14px;
