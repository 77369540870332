@import '../../../styles/classes';

.container {
  @include scrollbar;
  height: 100vh;
  overflow-y: scroll;

  .wrapper {
    max-width: 120rem;
    margin: auto;
    padding: 0 2rem;

    .header {
      height: 7rem;
      display: flex;
      align-items: center;

      .mark {
        height: 4rem;
        width: inherit;
        cursor: pointer;
      }

      .type {
        height: 2rem;
        width: inherit;
        margin-left: 2rem;
      }
    }

    .tab {
      .tab-item {
        cursor: pointer;
        position: relative;

        &.active {
          color: $primary;
        }

        .border {
          background: $primary;
          width: 16px;
          height: 2px;
          margin: 4px auto auto;
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }
  }
}
