@import 'variables';

@mixin scrollbar($custom-background: $background-darker-100) {
  &::-webkit-scrollbar {
    background: $custom-background;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($white, 0.2);
    border: 0.5rem solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 1rem;

    &:hover {
      background: rgba($white, 0.4);
      border: 0.5rem solid transparent;
      background-clip: padding-box;
      -webkit-border-radius: 1rem;
    }
  }
}

@mixin mobile {
  @media (max-width: 72rem) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 72rem) {
    @content;
  }
}
