@import 'src/styles/classes';

.control {
  display: flex;
  align-items: center;
}

.label {
  font-size: 14px;
  display: block;
  cursor: pointer;
}

.checkbox {
  width: 2rem;
  height: 2rem;
  background: $background-lighter-100;
  border-radius: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;

  &.selected {
    background: $primary;
  }
}

.hidden {
  display: none;
}

.mobile-instruction {
  @include desktop {
    display: none;
  }
}

.zoom-container {
  width: 100vw !important;
  height: 100vh !important;
  background: initial;
}
