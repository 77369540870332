@import 'src/styles/classes';

.club {
  background: $background-lighter-100;

  .card-wrapper {
    padding-top: 16px;

    .card {
      background: $background-lighter-200;
      padding: 16px;

      &.pinned {
        border: 1px solid $secondary;
      }

      .section {
        font-weight: 600;
        margin-bottom: 8px;
      }

      .avatar {
        width: 32px;
        height: 32px;
      }

      .role {
        font-size: 8px;
        margin-top: 4px;
        color: $secondary;
      }

      .moment {
        font-size: 10px;
        color: rgba(#ffffff, 0.4);
      }

      .engagement {
        color: rgba(#ffffff, 0.8);
        margin-top: 8px;

        .count {
          margin-left: 4px;
        }
      }
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }
}
