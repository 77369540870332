@import 'src/styles/classes';

.control {
  display: flex;
  align-items: center;
}

.label {
  font-size: 14px;
  display: block;
  cursor: pointer;
  margin-bottom: 0 !important;
}

.checkbox {
  width: 2rem;
  height: 2rem;
  background: $background-lighter-100;
  border-radius: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;

  &.level-100 {
    background: $background-lighter-300;
  }

  &.selected {
    background: $primary;
  }
}

.hidden {
  display: none;
}
