@import 'src/styles/classes';

.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;

  .background {
    width: 100%;

    .blurb {
      z-index: 100;
      position: absolute;
      bottom: 4rem;
      left: 4rem;
    }
  }

  .layout {
    width: 100%;
    height: 100%;
    position: absolute;

    @include mobile {
      width: 100%;
      height: 100%;
      overflow-y: scroll;

      .layout-wrapper {
        height: 100%;
      }
    }

    .layout-wrapper {
      max-width: 50rem;
      margin: 0 2rem;
      position: relative;

      .show-password {
        position: absolute;
        margin-top: -3.35rem;
        right: 5rem;
        cursor: pointer;
      }

      @include desktop {
        width: 50rem;
        position: unset;

        .show-password {
          position: initial;
          margin-top: -3.35rem;
          margin-left: 36rem;
          cursor: pointer;
        }

        .footer {
          position: absolute;
          bottom: 2rem;
          left: 0;
          right: 0;
          margin: auto;
          width: 50rem;
        }
      }

      .footer {
        background: rgba($background-lighter-400, 0.6);
        padding: 1rem 2rem;
        font-size: $size-7;
        margin-top: 2rem;

        ul {
          display: flex;
          justify-content: space-between;

          li {
            cursor: pointer;
          }
        }
      }
    }
  }

  .breaking {
    background: rgba($background-lighter-400, 0.6);
    font-size: $size-7;
    margin-top: 2rem;

    .breaking-banner-wrapper {
      background-color: rgba(#000000, 0.2);

      .breaking-banner {
        height: 23px !important;
        width: 126px !important;
      }
    }
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: center;

    .phoneNo {
      width: 75%;
    }

    .box {
      padding: 4rem;
      background: rgba($background-lighter-400, 0.6);
      position: relative;

      .logo {
        height: 8rem;
        object-fit: contain;
        object-position: center;
      }

      .logotype {
        height: 3rem;
        object-fit: contain;
        object-position: center;
      }

      .special {
        padding: 0 !important;
        background: rgba($background, 0.4);
        input {
          padding: 0 1rem;
          // border-radius: 1rem;
        }
        &:focus-within {
          background: rgba($background, 0.6);
        }
      }

      .download-app {
        margin-top: 5rem;

        img {
          height: 4rem;
          width: inherit;
          margin: auto;
        }
      }
    }
  }

  .background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    animation: background-fade-in 10s;
    object-position: left center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .breaking-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .beta {
    height: 31px !important;
    width: 29px !important;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }

  .sign-in {
    width: 120px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@keyframes background-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
input.termsOfUse {
  height: 2rem;
  width: 20px;
}
