@import 'src/styles/classes';

.content {
  padding: 4rem;

  .header {
    font-size: $size-4;
    font-weight: 700;
  }

  .request-types {
    display: flex;
    justify-content: space-evenly;

    .type {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem;
      width: 20rem;
      height: 25rem;
      border-radius: 1rem;
      border: 1px solid $primary;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: $background-lighter-200;
      }
    }

    @include mobile {
      flex-wrap: wrap;

      .type {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }

  .subheader {
    font-size: $size-5;
    font-weight: 600;
  }

  select {
    background: $background-lighter-200;
  }
}
