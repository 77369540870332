@import 'src/styles/classes';

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.container {
  margin-top: 7rem;
  height: calc(100vh - 7rem);
}

.right-sidebar {
  position: absolute;
  width: 35rem;
  right: -35rem;
  top: 8rem;
  background: $background-lighter-300;
  padding: 2rem 0;
  height: calc(100vh - 9rem);
  box-shadow: rgba($black, 0.4) 0 1rem 2rem 0;
  transition: right 250ms ease;

  &.show {
    right: 1rem;
  }

  @include mobile {
    width: 100%;
    top: 7rem;
    box-shadow: unset;
    transition: unset;
    height: calc(100vh - 7rem);
    right: 0;

    &.show {
      right: 0;
    }
  }
}

.player {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top: 0;
}

.toasts {
  position: fixed;
  top: 9rem;
  flex-direction: column;
  right: 4rem;
}
.pointer {
  cursor: pointer;
}
