@import 'src/styles/classes';

.music {
  background: $background-lighter-100;

  .singles:first-child {
    padding-top: 0 !important;
  }
  .singles {
    padding-top: 2rem;

    &:last-child {
      padding-bottom: 2rem;
    }
  }
}

.album-group {
  display: flex;
  flex-wrap: wrap;
  margin: -1px;
  padding: 0 1px;
  .album {
    margin-left: 1px;
    margin-right: 1px;
    width: 158px;
    margin-bottom: 2rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    .thumbnail {
      width: 158px;
      height: 158px;
      cursor: pointer;
    }

    .name {
      font-size: 12px;
    }

    .views {
      color: rgba($foreground, 0.4);
      font-size: 10px;
    }

    img {
      &.blur {
        filter: blur(0.8rem);
      }
    }

    .frosted {
      background: rgba(155, 0, 195, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 158px;
      height: 158px;
      top: 0;
    }
  }
}

.menu {
  background: $background-lighter-100;
  padding: 3rem 3rem 2rem 3rem;
}

.album-wrapper {
  min-height: 20rem;
  display: flex;
  align-items: center;
  padding: 2rem 4rem;
  background: $background-lighter-200;
  cursor: pointer;
  @include mobile {
    padding: 2rem;
    height: unset;
  }

  .album-details {
    height: 100%;
    display: flex;

    .album-art {
      overflow: hidden;

      img {
        width: unset;

        &.blur {
          filter: blur(3rem);
        }
      }
    }
  }
}

.play-button {
  position: relative;

  &.album-art {
    height: 25rem;

    @include mobile {
      height: 25rem;
    }
  }

  .hovered,
  .locked {
    position: absolute;
    background: rgba($black, 0.4);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
  }

  &:hover {
    .hovered {
      visibility: visible;
    }
  }

  .locked {
    visibility: visible;
  }
}
.pointer {
  cursor: pointer;
}
