.reaction-container {
  position: absolute;
  bottom: 60px;
  right: 60px;
  transform: translatey(0px);
  animation: float 2s ease-in-out;
  background-color: transparent;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  20% {
    opacity: 0.8;
  }
  40% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
    transform: translatey(-240px);
  }
}
