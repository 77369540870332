@import 'src/styles/classes';

.container {
  .content {
    .main {
      max-width: 114rem;
      margin: auto auto 12rem;

      .big-section {
        margin-bottom: 8rem;
      }

      .section {
        font-weight: 600;
        font-size: $size-6;
      }

      .fluid-wrapper {
        .fluid {
          flex-wrap: wrap;

          @include mobile {
            margin-right: 0;
          }
        }
      }

      .tile-sm {
        margin: 3rem 2rem 0;
        cursor: pointer;

        .image-wrapper {
          .event {
            width: 18rem;
            height: 11rem;
          }
        }

        .wrap-text {
          width: 18rem;
          color: $primary;

          .sub {
            color: $foreground;
          }
        }

        .sub {
          font-size: 10px;
        }
      }

      .tile-lg {
        margin-left: 4px;
        margin-right: 4px;
        position: relative;

        .image-wrapper {
          width: 296px;
          height: 160px;
        }

        .sub {
          position: absolute;
          left: 8px;
          bottom: 8px;
          font-size: 16px;
          font-weight: 600;
          text-shadow: 1px 1px rgba(#000000, 0.4);
        }
      }
    }
  }
}

.event-modal {
  padding: 5rem;
  display: flex;

  .details {
    margin-left: 5rem;

    .title {
      font-family: $font-heavy;
      font-size: 4rem;
    }

    .purchase {
      margin-top: 5rem;
    }
  }
}
