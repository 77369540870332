@import 'src/styles/classes';

.frame {
  height: 100vh;
  width: 100vw;
  max-height: 100%;
  max-width: 100%;
  background: $background-lighter-100;
}
.room {
  min-height: 400px;
}
.track {
  height: 100vh;
  max-height: 100%;
  video {
    height: 100%;
    width: 100%;
  }
}

.local {
  height: 200px;
  position: absolute;
  top: 3rem;
  right: 3rem;
  background-color: black;
  .name {
    font-size: 10px;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    width: 100%;
  }
  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.hide {
  display: none;
  visibility: hidden;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10rem;
  .control-icon {
    z-index: 100;
    height: 7rem !important;
    width: 7rem !important;
  }
}
.status {
  display: flex;
  position: absolute;
  bottom: 2rem;
  left: 2rem;

  .mic-status {
  }
}

.time-lapse-container {
  display: flex;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  .time-lapse {
  }
}

.merchantImg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
  height: 100vh;
  img {
    width: fit-content;
    height: 240px;
    border-radius: 50%;
  }
}

.recording-participant {
  position: absolute;
  top: 2rem;
  left: 2rem;
  display: flex;
  align-items: center;
  .record-status {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    &.on {
      background-color: red;
    }
    &.off {
      background-color: gray;
    }
  }
}

.recording-local {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 10px;
  .record-status {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    &.on {
      background-color: red;
    }
    &.off {
      background-color: gray;
    }
  }
}

.countdown-timer {
  position: absolute;
  top: 2rem;
  width: 100%;
  text-align: center;
}
