@import 'src/styles/classes';

.sidebar-button {
  background: $background-lighter-300;
  position: fixed;
  top: 1.5rem;
  left: 6rem;
  z-index: 10;
  padding: 0;
  width: 4rem;
  height: 4rem;
  display: none;

  &:active {
    background: $background-lighter-300;
  }

  &.active {
    background: rgba($white, 0.2);
  }

  @include desktop {
    display: none;
  }
}

.container {
  display: flex;

  .sidebar {
    background: $background-darker-100;
    padding: 2rem 0;
    height: calc(100vh - 7rem);
    width: 25rem;
    transition: 250ms ease;
    overflow: hidden;
    border-right: 1px solid $background-lighter-100;
    position: absolute;

    @include mobile {
      width: 20rem;
      transform: translateX(0);

      &.hide {
        transform: translateX(-20rem);
      }
    }

    .hide-sidebar {
      display: flex;
      align-items: center;
      padding: 1rem 2rem;
      position: absolute;
      bottom: 0;
      width: 100%;
      cursor: pointer;

      @include desktop {
        display: none;
      }

      .arrow {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        border: 1px solid $white;
        justify-content: center;
      }

      &:hover {
        background: $background-lighter-100;
      }
    }

    .menu {
      .menu-item {
        padding: 1rem 2rem;
        cursor: pointer;

        &.active {
          background: $background-lighter-200;
          border-left: 0.5rem solid $primary;
          padding-left: calc(2rem - 0.5rem);
        }

        &:hover {
          background: $background-lighter-100;
        }
      }
    }
  }

  .sidebar-open {
    position: absolute;
    width: 1rem;
    left: 0;
    height: 100%;
    cursor: pointer;

    &:hover {
      background: rgba($primary, 0.6);
    }
  }

  .main {
    @include scrollbar;
    flex: 1;
    height: calc(100vh - 7rem);
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 25rem;
    transition: 250ms ease;

    @include mobile {
      margin-left: 20rem;

      &.hide {
        margin-left: 0;
      }
    }

    .content {
      max-width: 82rem;
      padding: 0 1rem;
      margin: 2rem auto;

      &.wide {
        @include desktop {
          width: 122rem;
          max-width: unset;
        }
      }
    }
  }
}
