@import 'src/styles/classes';

.page-title div {
  cursor: pointer;
}

.trubucks-balance {
  padding: 2rem;
  margin: 3rem 0 3rem 0;
  // max-width: 50rem;
  border-radius: 1rem;
  height: 15rem;
  position: relative;
  overflow: hidden;
  box-shadow: rgba($black, 0.4) 0 1rem 2rem 0;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .details {
    position: relative;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    color: rgba($white, 0.9);
    font-weight: 600;

    .balance {
      font-size: 32px;
      font-weight: 400;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      line-height: 0;
    }
  }
}

.bundles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mobile {
    justify-content: space-evenly;
    margin-bottom: -2rem;

    li {
      margin-bottom: 2rem;
    }
  }

  li {
    padding: 1rem;
    text-align: center;
    width: 12rem;
    height: 12rem;
    cursor: pointer;
    position: relative;
    color: rgba($black, 0.8);
    border-radius: 1rem;
    overflow: hidden;

    .background {
      position: absolute;
      top: 0;
      left: 0;
    }

    .detail {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .price {
        font-size: $size-4;
        font-weight: 600;
        text-align: right;
      }
    }
  }

  &.has-selection {
    li:not(.active) {
      opacity: 0.2;
    }
  }
}

.finalize {
  background: $background-lighter-200;
  padding: 2rem;

  .total {
    border-top: 1px solid rgba($white, 0.2);
    margin-top: 1rem;
    padding-top: 1rem;
    text-align: right;
  }
}

.amount {
  font-size: $size-6;
  display: flex;
  align-items: center;
  line-height: 0;
}

.cursor {
  cursor: pointer;
}

.rewards-row {
  &:hover {
    background: $background-lighter-400 !important;
  }
}
