@import 'src/styles/classes';

.content {
  padding: 4rem;
  text-align: center;

  .danger {
    border-color: $red;
  }
}
