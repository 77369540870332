@import 'src/styles/classes';

.content {
  padding: 4rem;

  .header {
    font-size: $size-5;
    font-weight: 600;
  }

  .cards {
    display: flex;
    justify-content: space-evenly;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem;
      width: 20rem;
      height: 25rem;
      border-radius: 1rem;
      border: 1px solid $primary;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: $background-lighter-200;
      }
    }
  }

  .invited {
    height: 20rem;
    background: $background;

    .list {
      li {
        display: flex;
        align-items: center;
        padding: 1rem;
        cursor: pointer;

        &:hover {
          background: $background-darker-100;
        }
      }
    }
  }
}
