@import 'src/styles/classes';

.collection-card {
  cursor: pointer;
  width: 50rem;

  .thumbnail {
    margin: 0 auto;
    width: 100%;
    object-fit: contain;
    max-height: 480px;

    &.thumbnail-video {
      position: relative;

      video {
        width: 100%;
        height: 100%;
        display: block;
        max-height: 480px;
      }

      .play-video {
        background: rgba(#000000, 0.2);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 64px;
          filter: invert(100%);
        }
      }
    }
  }

  .thumbnail-audio {
    .preview-audio {
      width: 280px;
      height: 280px;
      margin: auto;
    }
    .play-audio {
      display: block;
      width: 100%;
      height: 24px;
      margin-top: 0.5rem;
    }
  }
}

.zoom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.fullscreen-video {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  z-index: 100000;

  .close-modal {
    filter: invert(100%);
  }

  video {
    width: 100%;
    height: 100%;
  }
}

.close-modal {
  cursor: pointer;
  z-index: 100000;
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 1rem;
  background-color: rgba(#000000, 0.2);
  border-radius: 0.5rem;
  font-size: 2rem;
  line-height: 1;
}
