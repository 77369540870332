@import 'src/styles/classes';

.container {
  display: flex;

  .sidebar-open {
    position: absolute;
    width: 1rem;
    left: 0;
    height: 100%;
    cursor: pointer;

    &:hover {
      background: rgba($primary, 0.6);
    }
  }

  .feed {
    @include scrollbar;
    flex: 1;
    height: calc(100vh - 7rem);
    overflow-y: auto;
    margin-left: 30rem;
    transition: 250ms ease;

    @include mobile {
      margin-left: 6rem;

      &.hide {
        margin-left: 0;
      }
    }

    .feed-wrapper {
      max-width: 82rem;
      padding: 0 1rem;
      margin: auto;

      .post {
        margin-top: 2rem;

        &:last-child {
          margin-bottom: 2rem;
        }
      }
    }

    .feed-skeleton {
      width: 100%;
      margin: auto;

      > div {
        margin-top: 2rem;
      }
    }

    .sticky {
      width: 80rem;
      margin: auto;
      display: flex;
      align-items: center;
      background: $background-lighter-300;
      border-bottom: 1px solid $background-lighter-100;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 100;
      cursor: pointer;

      .avatar {
        border-radius: 0;
        width: 12rem;
        height: 12rem;
      }

      .views {
        font-size: $size-7;
        color: $foreground-muted;
      }
    }

    .feed-options {
      width: fit-content;
      .calendar-option-container {
        position: relative;

        .calendar-icon {
          padding: 8px;
          font-size: 16px !important;
          background: $background-lighter-300;
          cursor: pointer;
        }

        .calendar-badge {
          width: 1rem;
          height: 1rem;
          margin-left: 2.8rem;
          border-radius: 50%;
          background: $olympus;
          position: absolute;

          &.hidden {
            visibility: hidden;
          }
        }
      }
    }
  }

  .feed-options {
    width: fit-content;
    .calendar-icon,
    .events,
    .request,
    .messages {
      padding: 8px 10px;
      background: $background-lighter-300;
      cursor: pointer;
      margin-right: 1rem;
      font-size: 12px !important;
    }
    .messages {
      padding: 8px 10px;
    }
    .calendar-badge {
      width: 1rem;
      height: 1rem;
      margin-left: 2.8rem;
      border-radius: 50%;
      background: $olympus;
      position: absolute;

      &.hidden {
        visibility: hidden;
      }
    }
  }
}

.hide-on-mobile {
  @include mobile {
    display: none;
  }
}
