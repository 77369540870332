@import 'src/styles/classes';

.poll {
  background-color: $background-lighter-200;
  width: 40rem;
  position: fixed;
  top: 5rem;
  padding: 2rem;
  z-index: 100000;
  opacity: 0.6;
  transition: 0.5s ease;

  &:hover {
    opacity: 1;
  }

  &.hide {
    transform: translateX(-100%);
    .poll-toggle {
      transform: rotate(180deg);
    }
  }

  &.show {
    transition: 0.5s;
    .poll-toggle {
    }
  }

  .poll-toggle {
    width: 4rem;
    height: 4rem;
    background: $background-lighter-200;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -4rem;
    top: 0;
    position: absolute;
    cursor: pointer;
    font-size: $size-8;
  }
  .option {
    width: 100%;
    margin-bottom: 1rem;

    &:first-child {
    }
  }

  .result {
    .selected {
      background-color: rgba($primary, 0.6);
    }
    .others {
      background-color: rgba($secondary, 0.6);
    }
  }
}
