@import 'variables';
@import 'mixins';

.flexbox {
  display: flex;
  align-items: center;

  &.flexbox-start {
    align-items: flex-start;
  }

  &.flexbox-end {
    align-items: flex-end;
  }

  .flex {
    flex: 1;
  }
}

.full-height {
  height: 100%;
}

.hidden {
  visibility: hidden;
}

.button {
  cursor: pointer;
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.8);
  }

  .mobile-instruction {
    position: absolute;
    top: 1.5rem;
    font-size: $size-7;
    pointer-events: none;
  }

  .modal-content {
    width: 70rem;
    background: $background-lighter-100;
    z-index: 1010;

    @include mobile {
      min-width: unset;
      width: 100%;
      height: calc(100% - 5rem);
      margin-top: 5rem;
    }
  }
}

.link {
  color: $primary;
  cursor: pointer;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  flex: none;
}

.tab {
  display: flex;

  .tab-item {
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

.label {
  font-size: 12px;
}

.l-1 {
  line-height: 1;
}

.is-icon {
  line-height: 0;
}

.small {
  font-size: 12px;
}

.table {
  border-collapse: collapse;
}

.has-text {
  &-secondary {
    color: $horizon;
  }

  &-muted {
    color: $foreground-muted;
  }

  &-danger {
    color: $red;
  }

  &-success {
    color: $green;
  }

  &-legacy {
    color: $legacy;
  }
}

.is-text {
  &-lowercase {
    text-transform: lowercase;
  }
}

.wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table {
  width: 100%;
  text-align: left;

  th {
    background: $background-darker-100;
    font-weight: 600;
  }

  tr {
    &:nth-child(odd) {
      background: $background-lighter-200;
    }

    &:nth-child(even) {
      background: $background-lighter-100;
    }
  }

  th,
  td {
    padding: 1rem;
    vertical-align: center;
  }
}

.is-size-subtitle {
  font-size: 12px;
}

.is-size-8 {
  font-size: 10px;
}

.w {
  &-50 {
    width: 50%;
  }
}

.legal {
  .header {
    font-size: $size-6;
    font-weight: 600;
  }

  .subheader {
    font-size: $size-normal;
    font-weight: 600;
    text-decoration: underline;
  }

  a {
    color: $primary;
  }

  p {
    margin-bottom: 2rem;
  }

  ul {
    list-style-type: disc;
    margin-bottom: 2rem;
    padding-left: 5rem;

    li {
      margin-bottom: 1rem;
    }
  }

  ol {
    padding-left: 5rem;
    margin-bottom: 2rem;

    ol {
      list-style-type: lower-alpha;
      margin-bottom: 0;

      ol {
        list-style-type: lower-roman;
        margin-bottom: 0;
      }
    }
  }

  table {
    td {
      padding-bottom: 2rem;

      &:first-child {
        width: 20rem;
        vertical-align: top;
      }
    }
  }
}

:global(.control-group) {
  &.inline-controls {
    .row {
      display: flex;
      margin: 0 -1rem;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      > div {
        padding: 0 1rem;
      }

      .w-25 {
        width: 25%;
      }

      .w-50 {
        width: 50%;
      }

      .w-75 {
        width: 75%;
      }

      .w-100 {
        width: 100%;
      }
    }
  }

  .control:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.sidebar {
  background: $background-darker-100;
  padding: 2rem 0;
  height: calc(100vh - 7rem);
  position: absolute;
  transition: 250ms ease;

  .section {
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 0 2rem;
  }

  .creator {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.75rem 2rem;
    width: 30rem;

    &.highlight {
      border-left: 0.5rem solid $primary;
      background: $background-lighter-200;
      padding-left: calc(2rem - 0.5rem);
    }

    &:hover {
      background: $background-lighter-100;
    }

    .avatar {
      width: 4rem;
      height: 4rem;
      flex-shrink: 0;
      overflow: hidden;

      &.live {
        box-shadow: 0 0 0 0.25rem $red;
      }
    }

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 1rem;
    }
  }

  .sidebar-skeleton {
    padding: 0 2rem;

    @include mobile {
      padding: 0 1rem;

      .desktop {
        display: none;
      }
    }
  }

  .hide-sidebar {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    cursor: pointer;

    @include desktop {
      display: none;
    }

    .arrow {
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      border: 1px solid $white;
      justify-content: center;
    }

    &:hover {
      background: $background-lighter-100;
    }
  }

  @include mobile {
    transform: translateX(0);

    &.hide {
      transform: translateX(-6rem);
    }

    .section {
      border-top: 1px solid $background-lighter-100;
      margin-top: 1rem;

      span {
        display: none;
      }
    }

    .creator {
      width: unset;
      padding: 0.5rem 1rem;

      &.highlight {
        padding-left: calc(1rem - 0.25rem);
        border-width: 0.25rem;
      }

      .name {
        display: none;
      }
    }
  }
}

.tab {
  .tab-item {
    cursor: pointer;
    position: relative;

    &.active {
      color: $primary;
    }

    .border {
      background: $primary;
      width: 2rem;
      height: 0.25rem;
      margin: 0.5rem auto auto;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
}

.text-center {
  text-align: center;
}

.breadcrumb {
  font-size: 12px;
  height: 4rem;

  ul {
    display: inline-block;
    height: 4rem;
    margin: 0;
    padding: 0;
    border-radius: 0.5rem;
    border: 1px solid $primary;
    overflow: hidden;
  }

  li {
    display: inline-block;
    line-height: 4rem;
    padding: 0 2rem 0 3rem;
    position: relative;

    &:first-child {
      padding-left: 2rem;
    }

    &:last-child {
      padding-right: 2rem;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 2rem solid transparent;
      border-bottom: 2rem solid transparent;
      border-left: 1rem solid;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 100%;
    }

    &:before {
      border-left-color: $primary;
      margin-left: 1px;
    }

    &:after {
      border-left-color: $background-lighter-100;
    }
  }
}

.form-group {
  .row {
    display: flex;
    margin: 0 -1rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    > div {
      padding: 0 1rem;
    }

    .w-25 {
      width: 25%;
    }

    .w-33 {
      width: 33.33%;
    }

    .w-50 {
      width: 50%;
    }

    .w-100 {
      width: 100%;
    }
  }
}

.control {
  .label {
    margin-bottom: 0.5rem;
    display: block;
  }

  .field {
    background: $background-lighter-200;
    border-radius: 0.75rem;
    overflow: hidden;
    padding: 0 1rem;
    border: 1px solid transparent;

    &.is-danger {
      border-color: $red;
    }

    &:focus-within:not(.is-readonly) {
      background: $background-lighter-300;

      .icon {
        color: $foreground;
      }
    }

    .icon {
      line-height: 1;
      color: rgba($foreground, 0.4);

      &.icon-left {
        margin-right: 8px;
      }

      &.icon-right {
        margin-left: 8px;
      }
    }

    .input {
      border: none;
      outline: none;
      background: none;
      padding: 0;
      width: 100%;
      font-size: $size-normal;
      color: $foreground;
      font-family: $font-family;
      height: 34px;
    }

    textarea {
      border: none;
      outline: none;
      background: none;
      resize: none;
      padding: 1rem 0;
      margin: 0;
      width: 100%;
      font-size: $size-normal;
      color: $foreground;
      font-family: $font-family;
    }
  }
}

.center-content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
