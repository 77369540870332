@import 'src/styles/classes';

.post {
  background: $background-lighter-200;
  margin-left: auto;
  margin-right: auto;

  &.promoted {
    border: 1px solid $primary;
  }

  .header {
    padding: 1rem 2rem;
    position: relative;

    .avatar {
      height: 5rem;
      width: 5rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    .creator {
      font-weight: 600;
      cursor: pointer;
    }

    .moment {
      font-size: $size-8;
      color: rgba(#ffffff, 0.4);

      &.promoted {
        color: $primary;
      }
    }

    .more {
      position: absolute;
      right: 1rem;
      top: 1rem;

      &.position-fix {
        top: 0.5rem;
      }
    }
  }

  .body {
    cursor: pointer;

    .album-wrapper {
      min-height: 20rem;
      display: flex;
      align-items: center;
      padding: 2rem 4rem;
      background: $background-lighter-300;

      @include mobile {
        padding: 2rem;
        height: unset;
      }

      .album-details {
        height: 100%;
        display: flex;

        .album-art {
          overflow: hidden;

          img {
            width: unset;

            &.blur {
              filter: blur(3rem);
            }
          }
        }
      }
    }

    .play-button {
      position: relative;

      &.album-art {
        height: 16rem;

        @include mobile {
          height: 14rem;
        }
      }

      .hovered {
        position: absolute;
        background: rgba($black, 0.6);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
      }

      &:hover {
        .hovered {
          visibility: visible;
        }
      }
    }
  }

  .footer {
    padding: 1rem 2rem 2rem;
    margin-top: 0.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    .engagement {
      color: rgba(#ffffff, 0.8);
      cursor: pointer;

      .highlight {
        .icon {
          color: $primary;
        }
      }

      .loading {
        cursor: progress;
      }

      .count {
        margin-left: 4px;
      }
    }
  }

  .image-wrapper {
    position: relative;
    overflow: hidden;

    .frosted {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(155, 0, 195, 0.25);
    }

    .blur {
      filter: blur(2rem);
    }
  }
}

.payment-confirmation-content {
  padding: 4rem;
  .payment-buttons {
    justify-content: center;
  }
}

.comment {
  background: $background-lighter-400;

  &:focus-within:not(.is-readonly) {
    background: $background-lighter-400;
  }
}

.comment-avatar {
  width: 3rem;
  height: 3rem;
}

.trubucks {
  cursor: pointer;

  &.open {
    color: $primary;
  }

  &:hover {
    color: $primary;
  }
}

.trubucks-panel {
  position: absolute;
  background: $background-lighter-300;
  padding: 1rem;
  width: 50%;
  border-radius: 1rem;
  float: right;
  right: 2rem;
  bottom: 8rem;
  box-shadow: rgb(#000000, 0.4) 0 1rem 2rem 0;

  .header {
    font-weight: 600;
    position: relative;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .close {
      position: absolute;
      right: 0;
      top: 0;

      &:hover {
        background: rgba($foreground, 0.2);
      }
    }
  }

  .predefined {
    display: flex;
    flex-wrap: wrap;

    li {
      flex: calc(100% / 3);
      text-align: center;
      margin-top: 0.5rem;

      .cost {
        width: 11rem;
      }
    }
  }

  .confirmation {
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .post {
      text-align: center;
    }
  }
}

.w-18 {
  width: 18%;
}
