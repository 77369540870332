@import 'src/styles/classes';

.videos {
  background: $background-lighter-100;

  .group-wrapper {
    padding-top: 16px;

    .group {
      // padding: 16px;
      background: $background-lighter-200;

      .section {
        font-size: 16px;
        font-weight: 600;
      }

      .video-group {
        display: flex;
        flex-wrap: wrap;
        margin: -1px;
        padding: 0 1px;

        .video {
          margin-left: 1px;
          margin-right: 1px;
          width: 158px;
          margin-bottom: 2rem;
          position: relative;
          cursor: pointer;
          overflow: hidden;

          .thumbnail {
            width: 158px;
            height: 158px;
            cursor: pointer;
          }

          .name {
            font-size: 12px;
          }

          .views {
            color: rgba($foreground, 0.4);
            font-size: 10px;
          }

          img {
            &.blur {
              filter: blur(0.8rem);
            }
          }
          .frosted {
            background: rgba(155, 0, 195, 0.25);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 158px;
            height: 158px;
            top: 0;
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }
}
