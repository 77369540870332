@import 'src/styles/classes';

.label {
  margin-bottom: 0.5rem;
  display: block;
}

.select {
  background: $background-lighter-200;
  border-radius: 0.75rem;
  padding: 0 1rem;
  height: 4.5rem;
  display: flex;
  align-items: center;

  select {
    appearance: none;
    background: none;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    color: $foreground;
    font-size: 14px;
    font-family: $font-family;

    &::-webkit-scrollbar {
      background: $background-lighter-200;
      width: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 5px;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover {
      -webkit-box-shadow: 0 0 0px 1000px $background-lighter-100 inset;
      -webkit-text-fill-color: #ffffff;
    }
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px $background-lighter-100 inset;
    }

    option {
      background: $background-lighter-200;
    }
  }
}

.layer-100 {
  .select {
    background: $background-lighter-100;
  }
}
