@import 'src/styles/classes';

.container {
  max-width: 80rem;
  margin: auto;
  padding-top: 2rem;

  @include mobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .main {
    .avatar {
      width: 12rem;
      height: 12rem;
    }

    .counters {
      .count {
        text-align: center;
        cursor: pointer;
      }
    }

    .edit {
      align-self: flex-start;
    }
  }

  .trophy {
    background: url('/assets/images/paper.png');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include mobile {
      @include scrollbar(transparent);
      overflow-x: auto;
      justify-content: unset;
    }
  }

  .past-events {
    .ticket {
      cursor: pointer;
      transform: scale(0.8) translateX(-12%) translateY(-6rem);
    }
  }

  .list {
    @include scrollbar(transparent);
    max-height: calc(100vh - 240px);
    overflow-y: auto;
    .item {
      margin-bottom: 1rem;

      .avatar {
        width: 5rem;
        height: 5rem;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }
}

.feed-skeleton {
  width: 100%;
  margin: auto;

  > div {
    margin-top: 2rem;
  }
}

.past-events {
  .tile {
    filter: grayscale(1);

    &:hover {
      filter: grayscale(0);
    }
  }

  .tile-skeleton {
    filter: grayscale(0);
  }
}

.fluid-wrapper {
  .fluid {
    flex-wrap: wrap;
    align-content: flex-start;

    @include mobile {
      margin-right: 0;
    }
  }
}

.events-scroll {
  @include scrollbar(transparent);
  overflow: scroll;
  height: calc(100vh - 25rem);
}
