@import 'src/styles/classes';

.promo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .video-container {
    height: 250px;
    width: 100%;
    min-width: 148px;
    overflow: hidden;
    border-radius: 0.2rem;

    cursor: pointer;

    .video-promo {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .info-container {
    width: 100%;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
    padding: 8px;

    .post-name {
      font-weight: bold;
    }

    .description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 60px;
    }
  }

  .play-button {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    position: absolute;
    visibility: visible;
    background-color: rgba(#000000, 0.2);
    transition: ease-in;
  }

  &:hover {
    .play-button {
      visibility: hidden;
    }
  }
}
