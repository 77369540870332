@import url('https://use.typekit.net/aax1btb.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
}

@font-face {
  font-family: 'TrueFonts';
  src: url('../assets/fonts/TrueFonts.eot?v6tw19');
  src: url('../assets/fonts/TrueFonts.eot?v6tw19') format('embedded-opentype'),
    url('../assets/fonts/TrueFonts.ttf?v6tw19') format('truetype'),
    url('../assets/fonts/TrueFonts.woff?v6tw19') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

%tf {
  font-family: 'TrueFonts', sans-serif !important;
  speak: never;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-bottom: -1px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tf {
  @extend %tf;
}

.tf-arrow-left:before {
  content: '\e900';
}
.tf-backward:before {
  content: '\e901';
}
.tf-calendar:before {
  content: '\e902';
}
.tf-call-off:before {
  content: '\e926';
}
.tf-call-on:before {
  content: '\e927';
}
.tf-camera-off:before {
  content: '\e928';
}
.tf-camera-on:before {
  content: '\e929';
}
.tf-caret-down:before {
  content: '\e903';
}
.tf-channel-private:before {
  content: '\e904';
}
.tf-channel-public:before {
  content: '\e905';
}
.tf-channel-text:before {
  content: '\e906';
}
.tf-channel-voice:before {
  content: '\e907';
}
.tf-close:before {
  content: '\e908';
}
.tf-club:before {
  content: '\e909';
}
.tf-comment:before {
  content: '\e90a';
}
.tf-contract:before {
  content: '\e90b';
}
.tf-crown:before {
  content: '\e90c';
}
.tf-discover:before {
  content: '\e90d';
}
.tf-edit:before {
  content: '\e90e';
}
.tf-ellipsis:before {
  content: '\e90f';
}
.tf-expand:before {
  content: '\e910';
}
.tf-facebook:before {
  content: '\e92d';
}
.tf-fan-rewards:before {
  content: '\e925';
}
.tf-fanbucks:before {
  content: '\e911';
}
.tf-forward:before {
  content: '\e912';
}
.tf-heart:before {
  content: '\e913';
}
.tf-instagram:before {
  content: '\e92e';
}
.tf-lightning:before {
  content: '\e914';
}
.tf-link:before {
  content: '\e92f';
}
.tf-lock:before {
  content: '\e915';
}
.tf-menu:before {
  content: '\e916';
}
.tf-messages:before {
  content: '\e917';
}
.tf-mic-off:before {
  content: '\e92a';
}
.tf-mic-on:before {
  content: '\e92b';
}
.tf-notification:before {
  content: '\e918';
}
.tf-pause:before {
  content: '\e919';
}
.tf-pin:before {
  content: '\e92c';
}
.tf-play:before {
  content: '\e91a';
}
.tf-plus:before {
  content: '\e91b';
}
.tf-request:before {
  content: '\e922';
}
.tf-search:before {
  content: '\e91c';
}
.tf-ticket:before {
  content: '\e923';
}
.tf-tiktok:before {
  content: '\e930';
}
.tf-trash:before {
  content: '\e924';
}
.tf-twitter:before {
  content: '\e931';
}
.tf-video-call:before {
  content: '\e91d';
}
.tf-video-message:before {
  content: '\e91e';
}
.tf-volume-high:before {
  content: '\e91f';
}
.tf-volume-low:before {
  content: '\e920';
}
.tf-volume-mute:before {
  content: '\e921';
}
.tf-youtube:before {
  content: '\e932';
}
