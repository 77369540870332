@import 'src/styles/classes';

.container {
  height: calc(100vh - 7rem);

  .content {
    @include scrollbar;
    overflow-y: auto;
    height: 100%;

    .main {
      max-width: 115rem;
      padding: 0 1rem;
      margin: auto auto 4rem;

      .menu {
        margin-top: 2rem;
      }

      .banner {
        position: relative;
        padding: 2rem 0 0 0;
        overflow: hidden;
        color: #fff;

        .background {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }

        .text {
          position: relative;
          font-size: 20px;
          font-family: $font-heavy;
          font-weight: 600;
          color: #fff;
          // text-transform: uppercase;
        }
      }

      .section {
        font-weight: 600;
        margin-bottom: 1rem;
      }

      .fluid-wrapper {
        @include scrollbar(transparent);
        overflow-x: auto;

        .fluid {
          margin-left: -0.5rem;
          margin-right: -0.5rem;
          max-width: 113rem;

          @include mobile {
            margin-right: 0;
          }
        }
      }
    }
  }
}
