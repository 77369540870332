@import 'src/styles/classes';

.date-picker {
  position: relative;

  .label {
    margin-bottom: 0.5rem;
    display: block;
  }

  .react-date-picker {
    width: 100%;
    border: none;
    background: $background-lighter-200;
    border-radius: 1rem;
    padding: 0 1rem;
    height: 4.5rem;

    &--open {
      background: $background-lighter-300;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__wrapper {
      border: none;
    }

    &__inputGroup {
      visibility: visible;
      color: #ffffff;
      &__input {
        color: #ffffff;
        &:invalid {
          background-color: transparent !important;
        }
      }
    }

    &__button {
      color: $foreground;
      padding: 0;
      line-height: 0;

      z-index: 999999;
      width: 224px;
      text-align: right;
      margin-left: -82px;
    }

    &__calendar {
      width: 100%;

      .react-calendar {
        background: $background-lighter-300;
        line-height: 1;
        border: none;
        padding: 1rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;

        &__navigation {
          height: 4rem;
          margin-bottom: 1rem;

          button {
            font-family: $font-family;
            color: $foreground;
            padding: 0;
            min-width: 4rem;
            font-size: 12px;

            &:hover {
              background: $background-lighter-400;
            }

            &:disabled {
              background: unset;
            }
          }
        }

        &__tile {
          padding: 0;
          height: 4rem;
          font-family: $font-family;
          font-size: 12px;
          color: #5f74f4;

          &:hover {
            background: $background-lighter-400;
          }

          &--now {
            color: $primary;
            background: none;
          }

          &--active {
            background: $primary;
            color: $foreground;
          }

          &--hasActive {
            background: $primary;
            color: $foreground;
          }
        }

        &__month-view {
          &__weekdays {
            font-family: $font-family;
            font-size: $size-8;

            &__weekday {
              padding: 0.5rem;
            }
          }

          &__days {
            &__day {
              &--neighboringMonth {
                color: $foreground-muted;
              }

              &:disabled {
                background: $background-lighter-300;
                color: rgba($black, 0.2);
              }
            }
          }
        }
      }
    }
  }

  .custom-input {
    position: absolute;
    bottom: 0;
    left: 0;
    //width: 100%; //if uncomment, calendar icon isn't clickable and doesn't show calendar

    .field {
      background: none;
    }
  }
}
