@import 'src/styles/classes';

.container {
  @include scrollbar;
  height: 100vh;
  overflow-y: scroll;

  &.preferences-container {
    height: unset;
    overflow-y: unset;
  }

  .wrapper {
    max-width: 70rem;
    margin: auto;
    padding: 0 2rem;

    .header {
      height: 7rem;
      display: flex;
      align-items: center;

      .mark {
        height: 4rem;
        width: inherit;
        cursor: pointer;
      }

      .type {
        height: 2rem;
        width: inherit;
        margin-left: 2rem;
      }
    }

    .header-name {
      &.preferences {
        font-size: $size-5;
        font-weight: 600;
      }

      &.login {
        font-size: $size-4;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .tab {
      .tab-item {
        cursor: pointer;
        position: relative;

        &.active {
          color: $primary;
        }

        .border {
          background: $primary;
          width: 16px;
          height: 2px;
          margin: 4px auto auto;
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }
  }

  .phoneNumber {
    flex-grow: 1;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .snackbar {
    position: fixed;
    z-index: 1000;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    max-width: 50rem;
    padding: 1rem 2rem;
    border-radius: 1rem;
    background-color: #0f2de1;
    color: white;
    box-shadow: rgba($black, 0.8) 0 1rem 2rem 0;

    display: flex;
    align-items: center;

    animation: fadein 250ms, fadeout 250ms 3.75s;

    @include mobile {
      max-width: calc(100vw - 5rem);
      min-width: calc(100vw - 5rem);
    }
  }

  .default {
    background-color: $background-lighter-300;
  }

  .success {
    background-color: $green;
  }

  .danger {
    background-color: $olympus;
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 4rem;
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 4rem;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }
}
