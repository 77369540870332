@import 'src/styles/classes';

.event-wrapper {
  @include scrollbar(transparent);
  overflow-x: auto;

  .events {
    width: 80rem;
  }
}

.tile {
  cursor: pointer;
}

.past-events {
  .tile {
    filter: grayscale(1);

    &:hover {
      filter: grayscale(0);
    }
  }
}

.event-modal {
  padding: 5rem;
  display: flex;

  .details {
    margin-left: 5rem;

    .title {
      font-family: $font-heavy;
      font-size: 4rem;
    }

    .purchase {
      margin-top: 5rem;
    }
  }
}

.fluid-wrapper {
  .fluid {
    flex-wrap: wrap;

    @include mobile {
      margin-right: 0;
    }
  }
}

.menu {
  margin-top: 2rem;
}
