@import 'src/styles/classes';

.container {
  max-width: 80rem;
  margin: auto;
}

.header {
  font-size: $size-5;
  font-weight: 600;
}

.menu {
  margin-top: 2rem;
}

.table-wrapper {
  @include scrollbar(transparent);
  overflow-x: inherit;

  .table {
    .user {
      cursor: pointer;
    }
    width: 120rem;
  }
}
