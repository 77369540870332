@import 'src/styles/classes';

.ticket {
  height: 48rem;
  width: 30rem;
  border-radius: 2rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0 1rem 2rem 0 rgba($black, 0.4);

  &.xxs {
    transform: scale(0.4);
    margin: calc(-24rem * 0.6) calc(-15rem * 0.6);
  }

  &.xs {
    transform: scale(0.6);
    margin: calc(-24rem * 0.4) calc(-15rem * 0.4);
  }

  &.sm {
    transform: scale(0.8);
    margin: calc(-24rem * 0.2) calc(-15rem * 0.2);
  }

  .wrapper {
    height: 100%;

    .artwork {
      object-fit: contain;
      object-position: top;
    }

    .artwork-half {
      object-fit: contain;
      object-position: top;
      border-radius: 2rem;
      padding: 5px 5px 0 5px;
    }
  }

  .stub {
    position: absolute;
    bottom: 0;
    height: 37.5%;
    width: 100%;
    padding: 1rem 2rem;
    user-select: none;
    cursor: default;

    .background {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 0 5px 2.75px 5px;
    }

    .details {
      position: relative;

      .name {
        font-size: 24px;
        font-family: nimbus-sans-extended, sans-serif;
        font-weight: 700;
        line-height: 2.5rem;
        height: 5rem;
        margin: 1rem 0;
        display: flex;

        span {
          display: flex;
          align-items: flex-end;
        }
      }

      .artist {
        font-size: 16px;
        font-weight: 600;
      }

      .date {
        text-transform: uppercase;
      }
    }
  }

  .punch {
    background: $background;
    height: 1rem;
    width: 4rem;
    border-radius: 0.5rem;
    position: absolute;
    top: 1.5rem;
    left: 0;
    right: 0;
    margin: auto;
  }

  .border {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 2rem;
    border: 0.75rem solid;
  }
}
