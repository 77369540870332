@import 'src/styles/classes';

.content {
  .banner {
    height: 15rem;
  }

  .stub {
    @include scrollbar;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 40rem;

    .header {
      font-size: $size-1;
      font-weight: 700;
      text-align: center;
      background: linear-gradient(to right, $newton, $olympus);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      line-height: 1;
    }

    .badge-header {
      display: flex;
      align-items: center;
      justify-content: center;

      .badge-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .badge {
          width: 60px;
        }
      }
    }
  }

  .button-subscribe {
    background: linear-gradient(to right, $legacy, $horizon);
  }

  .subscription-plan-list {
    .subscription-plan {
      cursor: pointer;
      border: 1px solid $primary;
      border-bottom: 0;
      padding: 2rem;

      &:hover {
        background-color: $background-lighter-300;
      }

      .subscription-plan-header {
        display: flex;
        align-items: center;
        width: 100%;

        .image {
          width: 5rem;
          height: 5rem;
        }
      }

      .subscription-plan-content {
        margin-left: 7rem;

        .subscription-plan-benefits {
          .details {
            li:before {
              content: '✓';
              margin-right: 1rem;
            }
          }

          .button-subscribe {
            background: linear-gradient(to right, $legacy, $horizon);
          }
        }
      }
    }

    .subscription-plan:last-child {
      border-bottom: 1px solid $primary;
    }
  }

  .payment-form {
    @include scrollbar;
    height: 60rem;
    padding: 2rem;
    overflow-y: auto;

    @include mobile {
      height: unset;
    }

    .subscription-plan {
      margin-top: 1rem;
      .subscription-plan-header {
        display: flex;
        align-items: center;
        width: 100%;

        .image {
          width: 5rem;
          height: 5rem;
        }
      }

      .subscription-plan-content {
        margin-left: 7rem;

        .subscription-plan-benefits {
          font-size: 12px;

          .details {
            li:before {
              content: '✓';
              margin-right: 1rem;
            }
          }

          .button-subscribe {
            background: linear-gradient(to right, $legacy, $horizon);
          }
        }
      }
    }

    .total {
      border-top: 1px solid rgba($white, 0.2);
      margin-top: 1rem;
      padding-top: 1rem;
      text-align: right;
    }

    .payment-gateway {
      background: $background-lighter-200;
      margin-top: 2rem;
      padding: 2rem;
    }
  }

  .video-box {
    background: rgba($black, 0.2);
    position: relative;

    .video-message {
      width: 100%;
    }
  }
}
