@import 'src/styles/classes';

.container {
  max-width: 80rem;
  margin: auto;

  @include mobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .list {
    @include scrollbar;
    max-height: calc(100vh - 240px);
    overflow-y: auto;
    .item {
      margin-bottom: 1.5rem;
      .pointer {
        cursor: pointer;
      }

      .avatar {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
