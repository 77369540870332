@import 'src/styles/classes';

.button {
  background: $primary;
  color: $foreground;
  border-radius: 0.5rem;
  height: 4rem;
  font-size: $size-normal;
  border: 1px solid transparent;
  font-family: $font-family;
  padding: 0 2rem;
  margin: 0;
  cursor: pointer;

  &:active {
    background: darken($primary, 5%);
  }

  &.secondary {
    border-color: $primary;
    background: none;
  }

  &.white {
    border-color: $white;
    background: none;
  }

  &.clear {
    background: none;
    padding: 0;
  }

  &.clear-primary {
    background: none;
    padding: 0;
    color: $primary;
  }

  &.danger {
    background: $olympus;
  }

  &.disabled,
  &:disabled {
    border-color: transparent;
    opacity: 0.6;
    cursor: default;
  }

  &.sm {
    font-size: $size-8;
    padding: 0 1rem;
    height: 3rem;
    border-radius: 0.5rem;
  }

  &.lg {
    height: 5rem;
    font-size: $size-6;
  }

  &.circle {
    border-radius: 50%;
    padding: unset;
    width: 3rem;
    height: 3rem;

    &.sm {
      width: 3rem;
    }
  }

  &.icon {
    line-height: 0;

    &-text {
      line-height: 0;
      display: flex;
      align-items: center;
    }
  }
}
