@import 'src/styles/classes';

.content {
  padding: 4rem;

  .header {
    font-size: $size-5;
    font-weight: 600;
  }

  .cards {
    display: flex;
    justify-content: space-evenly;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem;
      width: 20rem;
      height: 25rem;
      border-radius: 1rem;
      border: 1px solid $primary;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: $background-lighter-200;
      }
    }
  }

  .invited {
    @include scrollbar;
    height: 20rem;
    background: $background;
    overflow-y: auto;

    .list {
      li {
        display: flex;
        align-items: center;
        padding: 1rem;
        cursor: pointer;

        &:hover {
          background: $background-darker-100;
        }
      }
    }
  }
}

.danger {
  color: red;
  font-size: 12px;
}

.no-mb {
  margin: 0 !important;
}
