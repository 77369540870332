@import 'src/styles/classes';

.layout {
  display: flex;
  height: 100%;

  @include mobile {
    flex-direction: column;

    .theater {
      flex: unset;
      height: 30%;

      &.mobile-video-message {
        height: 100%;
      }
    }

    .siderail {
      width: 100%;
      height: 70%;

      .controls {
        display: none;
      }
    }
  }
}

.youtubeIframe {
  border: 0;
  height: 100%;
  width: 100%;
}

.theater {
  flex: 1;
  background: #000000;
  height: 100%;
  position: relative;

  .media {
    width: 100%;
    height: 100%;
    margin: auto;

    &.image {
      object-fit: contain;
    }

    &.audio {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      canvas {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .center-media {
        z-index: 10;

        .cover-art {
          width: 40rem;
          height: 40rem;
          margin: auto;

          @include mobile {
            width: 20rem;
            height: 20rem;
          }
        }

        .playback {
          text-align: center;
          color: black;

          .title {
            margin: 8px 0;

            .song {
              font-size: 16px;
              font-weight: 600;
            }
          }

          .seeker {
            height: 2px;
            position: relative;

            .playhead {
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              margin: 0;
              appearance: none;
              background: transparent;

              &::-webkit-slider-thumb {
                appearance: none;
              }

              &:hover {
                &::-webkit-slider-thumb {
                  background: $primary;
                  border: 1px solid $foreground;
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                }
              }
            }

            .progress {
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              appearance: none;
              pointer-events: none;

              &::-webkit-progress-bar {
                background: rgba(black, 0.4);
              }

              &::-webkit-progress-value {
                background: black;
              }
            }
          }

          .timers {
            font-size: 12px;
          }

          .controls {
            .play-pause {
              margin: 0 48px;
              width: 32px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .player-controls {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    bottom: 60px;
    padding: 14px;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    transition: all 0.3s ease-in-out;

    .player-seeker {
      height: 8px;
      bottom: 40px;
      position: absolute;
      width: calc(100% - 32px);
      overflow: hidden;
      .track-progress {
        height: 100%;
        background-color: $primary;
      }

      .playhead {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 0 1px 2px #888888;
        position: absolute;
        left: -6px;
        bottom: 35px;
      }

      &:hover {
        .playhead {
          visibility: visible;
        }
      }
    }

    .input-progress {
      -webkit-appearance: none;
      background-color: rgba($primary, 0.2);
      position: absolute;
      bottom: 37px;
      z-index: 9999;
      height: 8px;
    }

    .input-progress::-webkit-slider-runnable-track {
      height: 10px;
      -webkit-appearance: none;
      color: $primary;
      margin-top: -4px;
    }

    .input-progress::-webkit-slider-thumb {
      width: 14px;
      -webkit-appearance: none;
      height: 14px;
      cursor: ew-resize;
      background: white;
      border-radius: 14px;
    }
  }
  .actions {
    flex: auto;
  }
  .actions,
  .fullscreen {
    margin-top: 0.5em;

    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;

      &.full-screen {
        margin-left: auto;
      }

      i {
        background: none;
        color: white;
      }
    }

    .volume-wrapper {
      overflow: hidden;
      width: 28px;
      height: 20px;
      bottom: -12px;
      left: 35px;
      display: flex;
      transition: ease 250ms;
      margin: 0 0.75rem;
      .volume-toggle {
        width: 28px;
      }
      button i {
        padding-right: 2.5px;
      }
    }
    .volume-wrapper:hover {
      transition: ease 250ms;
      width: 155px;
      overflow: visible;
    }
    .volume-container {
      position: relative;
      width: 130px;
      overflow: hidden;
      .volume-level {
        height: 6px;
        background-color: $primary;
        position: absolute;
        z-index: 998;
        top: 6px;
      }
      .volume-control {
        margin: 0;
        -webkit-appearance: none;
        background-color: rgba($primary, 0.2);
        width: 100%;
        height: 6px;
        z-index: 1000;
        position: absolute;
        max-width: 125px;
        top: 6px;
      }
    }
  }

  .volume-control::-webkit-slider-runnable-track {
    height: 6px;
    -webkit-appearance: none;
    color: $primary;
    margin-top: -6px;
  }

  .volume-control::-webkit-slider-thumb {
    width: 14px;
    -webkit-appearance: none;
    height: 14px;
    cursor: ew-resize;
    background: white;
    border-radius: 14px;
  }

  .mute-btn {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .mute-btn i {
    background: none;
    color: white;
    font-size: 20px;
  }
}

.siderail {
  background: $background-darker-100;
  width: 320px;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;

  .controls {
    display: flex;
    min-height: 3rem;
  }

  .moment {
    font-size: 10px;
  }

  .engagement {
    color: rgba(#ffffff, 0.8);
    cursor: pointer;

    &.highlight {
      .icon {
        color: $primary;
      }
    }

    &:hover {
      .icon {
        color: lighten($primary, 10);
      }
    }

    .count {
      margin-left: 4px;
    }
  }

  .live-chat {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    .section {
      font-size: $size-7;
      font-weight: 600;
    }

    .chat-list-wrapper {
      flex: 1;
      @include scrollbar;
      max-height: calc(100vh - 16rem);
      overflow-y: auto;

      .chat-list {
        // @include scrollbar;
        // height: calc(100% - 18.5rem);
        // overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .comment {
      z-index: 1000;
      margin-top: 1rem;
    }

    .avatar {
      width: 2rem;
      height: 2rem;
    }
  }

  .comments {
    margin-top: 0.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    &.comments-audio {
      height: calc(100vh - 100rem);
    }

    .section {
      font-size: $size-7;
      font-weight: 600;
    }

    .comment-list {
      @include scrollbar;
      overflow-y: auto;
      height: calc(100vh - 23rem);
      margin: 0 -2rem;
    }

    .avatar {
      width: 2rem;
      height: 2rem;
    }

    .comment-input {
      position: fixed;
      bottom: 15px;

      .replying {
        background: $background-lighter-100;
        font-size: $size-7;
        padding: 0.5rem 2rem 1.5rem;
        margin-bottom: -1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
    }
  }

  .audio {
    max-height: 30rem;

    .album {
      font-size: 16px;
      font-weight: 600;
    }

    .tracks {
      @include scrollbar(transparent);
      margin: 1rem -4rem 0 -2rem;
      max-height: calc(100% - 28px);
      overflow-y: scroll;

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          cursor: pointer;

          &:nth-child(odd) {
            background: $background;
          }

          &:nth-child(even) {
            background: $background-darker-100;
          }

          &.selected {
            background: linear-gradient(to right, rgba($primary, 0.2), rgba($secondary, 0.2)) fixed;
          }

          &:hover {
            background: $primary;

            .is-track-playing {
              color: $foreground;
            }
          }

          td {
            height: 32px;
            padding: 0 8px;
            border: none;

            &.track-name {
              width: 100%;
            }

            &:first-child {
              padding-left: 16px;
            }

            &:last-child {
              padding-right: 16px;
            }

            .is-track-playing {
              line-height: 0;
              color: $primary;
            }
          }
        }
      }
    }
  }
}

.dark {
  color: rgba($foreground, 0.6);
  border: 1px solid rgba($foreground, 0.6);
  background: none;

  &:hover {
    background: rgba($foreground, 0.2);
  }
}

.close-player {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.trubucks {
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.trubucks-panel {
  position: absolute;
  background: $background-lighter-200;
  padding: 1rem;
  width: 100%;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: 250ms ease;
  border-radius: 1rem 1rem 0 0;

  &.open {
    visibility: visible;
    bottom: 4rem;
    opacity: 1;
  }

  .header {
    font-weight: 600;
    position: relative;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .close {
      position: absolute;
      right: 0;
      top: 0;

      &:hover {
        background: rgba($foreground, 0.2);
      }
    }
  }

  .predefined {
    display: flex;
    flex-wrap: wrap;

    li {
      flex: calc(100% / 3);
      text-align: center;
      margin-top: 0.5rem;

      .cost {
        width: 11rem;
      }
    }
  }

  .confirmation {
    height: 14rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .post {
      text-align: center;
    }
  }
}

.stream-status {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .tipbox {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    li {
      text-align: center;
      margin-top: 0.5rem;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }
}

.large {
  width: 18rem;
  height: 18rem;
}
