@import 'src/styles/classes';

.trophy-wrapper {
  @include scrollbar(transparent);
  overflow-x: auto;

  .trophy {
    background: $background-lighter-100;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80rem;

    .temp-trophy {
      width: 17rem;
      text-align: center;
      padding: 2rem;

      .placeholder {
        width: 12rem;
        height: 12rem;
        margin: auto;
        background: $background-lighter-200;
        border: 1px solid $foreground-muted;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        color: $foreground-muted;
      }

      .instruction {
        color: $foreground-muted;
        margin-top: 1rem;
      }
    }
  }
}

.table-wrapper {
  //@include scrollbar(transparent);
  //overflow-x: auto;

  .active-subs {
    width: 83rem;

    li {
      padding: 1rem;
      display: flex;
      align-items: center;

      &:nth-child(odd) {
        background: $background-lighter-200;
      }

      &:nth-child(even) {
        background: $background-lighter-100;
      }

      .fixed {
        width: 15rem;
        cursor: pointer;

        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .cancel {
        visibility: hidden;

        @include mobile {
          visibility: visible;
        }
      }

      &:hover {
        .cancel {
          visibility: visible;
        }
      }
    }

    .avatar-pill {
      display: flex;
      align-items: center;
      cursor: pointer;

      .avatar {
        width: 5rem;
        height: 5rem;
      }

      .badge {
        width: 3rem;
        height: 3rem;
        margin: 0 1rem;
      }
    }
  }
}

.no-subs {
  margin-top: 4rem;
  text-align: center;
}

.align-center {
  text-align: center;
}

.badge-img {
  height: 32px;
  width: 32px;
}
