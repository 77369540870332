@import 'src/styles/classes';

.container {
  @include scrollbar;
  height: 100vh;
  overflow-y: scroll;

  &.preferences-container {
    height: unset;
    overflow-y: unset;
  }

  .wrapper {
    max-width: 120rem;
    margin: auto;
    padding: 0 2rem;

    .header {
      height: 7rem;
      display: flex;
      align-items: center;

      .mark {
        height: 4rem;
        width: inherit;
        cursor: pointer;
      }

      .type {
        height: 2rem;
        width: inherit;
        margin-left: 2rem;
      }
    }

    .header-name {
      &.preferences {
        font-size: $size-5;
        font-weight: 600;
      }

      &.login {
        font-size: $size-4;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .tab {
      .tab-item {
        cursor: pointer;
        position: relative;

        &.active {
          color: $primary;
        }

        .border {
          background: $primary;
          width: 16px;
          height: 2px;
          margin: 4px auto auto;
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }
  }
}
