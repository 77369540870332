@import 'src/styles/classes';

.anchor {
  color: $primary;
  cursor: pointer;

  &.foreground {
    color: $foreground;
  }

  &.sm {
    font-size: 10px;
  }
}
